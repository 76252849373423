@import url(../fonts/fonts.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, html {
  font-size: 16px;
  font-family: ITCCentury, sans-serif;
  font-style: normal; }

.js-mobile .hide-mobile {
  display: none; }

.js-mobile .contentwrapper--explore {
  display: none; }

.js-desktop .contentwrapper--mobile {
  display: none; }

.js-no-interaction {
  pointer-events: none; }

.richtext, .project--desc-wrapper, .about-sidebar, .contentwrapper--mobile--desc {
  line-height: 1.2em; }
  .richtext p, .project--desc-wrapper p, .about-sidebar p, .contentwrapper--mobile--desc p {
    margin-bottom: 1em; }
  .richtext strong, .project--desc-wrapper strong, .about-sidebar strong, .contentwrapper--mobile--desc strong {
    font-weight: bold; }
  .richtext a, .project--desc-wrapper a, .about-sidebar a, .contentwrapper--mobile--desc a {
    color: inherit;
    text-decoration: none; }
    .richtext a:hover, .project--desc-wrapper a:hover, .about-sidebar a:hover, .contentwrapper--mobile--desc a:hover {
      font-style: italic;
      letter-spacing: -0.02em; }

.fontloader-italic {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none; }
  .fontloader-italic:after {
    content: "";
    font-style: italic; }

.contentwrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  overflow: hidden; }

.contentwrapper--list {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 40px;
  overflow: hidden; }
  .contentwrapper--list .search--label {
    font-size: 1.25em;
    padding-top: 50px;
    padding-bottom: 7px; }
    .contentwrapper--list .search--label:first-of-type {
      padding-top: 20px; }
  .contentwrapper--list .search--wrapper {
    max-width: 100%;
    display: none; }
    .contentwrapper--list .search--wrapper.is-visible {
      display: block; }
  .contentwrapper--list .search--input input {
    max-width: 100%;
    font-size: 5.5rem;
    font-family: ITCCentury, sans-serif;
    font-style: italic;
    outline-width: 0;
    border: 0;
    padding-top: 0.5em;
    text-indent: 0.21em;
    margin-left: -0.21em; }
    .contentwrapper--list .search--input input::placeholder {
      color: rgba(0, 0, 0, 0.99); }
  .contentwrapper--list .search--noresult {
    padding-top: 1rem; }
  .contentwrapper--list .contentwrapper--list--project {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    font-size: 5.5rem;
    text-transform: lowercase;
    margin-right: 0.25em;
    line-height: 1em;
    white-space: nowrap; }
    .contentwrapper--list .contentwrapper--list--project:after {
      content: ";"; }
    .contentwrapper--list .contentwrapper--list--project:before {
      pointer-events: none;
      position: absolute;
      font-style: italic;
      content: attr(data-title);
      opacity: 0; }
    .contentwrapper--list .contentwrapper--list--project:hover {
      color: #FFF; }
      .contentwrapper--list .contentwrapper--list--project:hover:before {
        color: #000;
        letter-spacing: -0.03em;
        opacity: 1; }
  .contentwrapper--list li {
    display: inline; }
  .contentwrapper--list .year--divider {
    font-size: 1.25em;
    padding-top: 50px;
    padding-bottom: 7px; }
    .contentwrapper--list .year--divider:first-of-type {
      padding-top: 20px; }

.contentwrapper--list-sidebar {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: 1.25rem;
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
  width: 220px; }
  .contentwrapper--list-sidebar .contentwrapper-filter {
    position: fixed;
    top: 40px;
    padding-top: 30px; }
  .contentwrapper--list-sidebar .contentwrapper-filter--search {
    cursor: pointer; }
    .contentwrapper--list-sidebar .contentwrapper-filter--search img {
      display: block;
      width: 14px; }
    .contentwrapper--list-sidebar .contentwrapper-filter--search:before {
      display: none; }
    .contentwrapper--list-sidebar .contentwrapper-filter--search.is-active:before {
      top: 0;
      display: block;
      background-color: #000; }
  .contentwrapper--list-sidebar li {
    padding-bottom: 0.2em;
    position: relative; }
    .contentwrapper--list-sidebar li a {
      display: block;
      padding-left: 0.75em;
      color: inherit;
      text-decoration: none;
      letter-spacing: 0.02em; }
    .contentwrapper--list-sidebar li:before {
      position: absolute;
      left: 0em;
      top: 0.1em;
      content: "";
      height: 0.55em;
      width: 0.55em;
      border-radius: 100%;
      border: 0.5px solid #000;
      pointer-events: none; }
    .contentwrapper--list-sidebar li.active:before {
      background-color: #000; }
    .contentwrapper--list-sidebar li.active.is-deactivated:before {
      background-color: transparent; }
  .contentwrapper--list-sidebar .contentwrapper--list-sidebar--projectpreview {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 220px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
    font-size: 0.85rem;
    line-height: 1.2em; }
    .contentwrapper--list-sidebar .contentwrapper--list-sidebar--projectpreview img {
      max-width: 132px;
      max-height: 132px;
      display: block;
      padding-bottom: 10px; }

.mainnav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #000;
  background-color: #FFF;
  font-size: 1.5rem;
  text-transform: lowercase;
  overflow: hidden; }
  .mainnav .mainnav-left {
    letter-spacing: 0.02em;
    white-space: nowrap; }
  .mainnav .mainnav-left, .mainnav .mainnav-right {
    width: 50%; }
  @media (min-width: 1025px) {
    .mainnav .mainnav-left, .mainnav .mainnav-center, .mainnav .mainnav-right {
      width: 33.33%; } }
  .mainnav .mainnav-center {
    text-align: center; }
    .mainnav .mainnav-center a:hover {
      font-style: italic; }
  .mainnav .mainnav-right {
    text-align: right; }
    .mainnav .mainnav-right a:hover {
      font-style: italic; }
  .mainnav li {
    list-style-type: none;
    display: inline-block; }
  .mainnav a {
    text-decoration: none;
    color: inherit; }

.wrapper--intro.js-blur .intro--image {
  transition: filter 1s ease;
  filter: blur(20px);
  -webkit-filter: blur(20px); }

.intro--image {
  position: fixed;
  overflow: hidden; }
  .intro--image img, .intro--image video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 3s ease; }
  .intro--image.intro--image-contain img, .intro--image.intro--image-contain video {
    object-fit: contain; }
  .intro--image:hover img, .intro--image:hover video {
    transform: scale(1.03); }
  .intro--image .intro--image--award-wrapper {
    display: block;
    position: absolute; }
  .intro--image .intro--image--award {
    position: absolute;
    z-index: 2;
    display: block; }
  .intro--image .intro--image--videocontrol {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fffd61;
    z-index: 1;
    cursor: pointer; }
    .intro--image .intro--image--videocontrol:before {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 33px);
      content: "\A";
      border-style: solid;
      border-width: 33px 0 33px 50px;
      border-color: transparent transparent transparent #fffd61; }
    .intro--image .intro--image--videocontrol:hover:before {
      position: absolute;
      left: calc(50% - 33px);
      top: calc(50% - 33px);
      content: "\A";
      border-style: solid;
      border-width: 33px 50px 33px 0;
      border-color: transparent #fffd61 transparent transparent; }
    .intro--image .intro--image--videocontrol.playing {
      display: none; }

.intro--image1 {
  left: 30vw;
  bottom: 100vh;
  height: 60vh;
  width: 50vw;
  z-index: 2; }
  .intro--image1.intro--image-overlap {
    z-index: 112; }

.intro--image2 {
  left: 0;
  bottom: -60vh;
  height: 60vh;
  width: 50vw;
  z-index: 1; }
  .intro--image2.intro--image-overlap {
    z-index: 111; }

.intro--image3 {
  right: 100vw;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3; }
  .intro--image3.intro--image-overlap {
    z-index: 113; }

.intro--image4 {
  left: 25vw;
  bottom: 100vh;
  height: 100vh;
  width: 50vw;
  z-index: 5; }
  .intro--image4.intro--image-overlap {
    z-index: 115; }

.intro--image5 {
  left: 100vw;
  bottom: 0;
  width: 40vw;
  padding-bottom: 40%;
  z-index: 6; }
  .intro--image5.intro--image-overlap {
    z-index: 116; }

.intro--image6 {
  left: 25vw;
  top: 100vh;
  height: 100vh;
  width: 50vw;
  z-index: 4; }
  .intro--image6.intro--image-overlap {
    z-index: 114; }

.intro--image7 {
  left: 0;
  top: 100vh;
  height: 100vh;
  width: 50vw;
  z-index: 0; }
  .intro--image7.intro--image-overlap {
    z-index: 110; }

.intro--image8 {
  left: 50vw;
  top: -100vh;
  height: 100vh;
  width: 50vw;
  z-index: 0; }
  .intro--image8.intro--image-overlap {
    z-index: 110; }

.projectwrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%; }

.projectcontrols {
  position: absolute; }
  .projectcontrols.controls-left {
    left: 0;
    top: 0;
    bottom: 0;
    width: 33vw;
    z-index: 11;
    cursor: url("../images/arrow_left@1x.png") 22 32, pointer;
    /* Legacy */
    cursor: -webkit-image-set(url("../images/arrow_left@1x.png") 1x, url("../images/arrow_left@2x.png") 2x) 22 32, pointer;
    /* Webkit */ }
  .projectcontrols.controls-right {
    right: 0;
    top: 0;
    bottom: 0;
    width: 33vw;
    z-index: 11;
    cursor: url("../images/arrow_right@1x.png") 22 32, pointer;
    /* Legacy */
    cursor: -webkit-image-set(url("../images/arrow_right@1x.png") 1x, url("../images/arrow_right@2x.png") 2x) 22 32, pointer;
    /* Webkit */ }
  .projectcontrols.controls-bottom {
    right: 0;
    left: 0;
    bottom: 0;
    height: 33vh;
    z-index: 10;
    cursor: url("../images/arrow_down@1x.png") 22 32, pointer;
    /* Legacy */
    cursor: -webkit-image-set(url("../images/arrow_down@1x.png") 1x, url("../images/arrow_down@2x.png") 2x) 22 32, pointer;
    /* Webkit */ }
    .projectcontrols.controls-bottom a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: inherit; }
  .projectcontrols.controls-top {
    right: 0;
    left: 0;
    top: 0;
    height: 33vh;
    z-index: 10;
    cursor: url("../images/arrow_up@1x.png") 22 32, pointer;
    /* Legacy */
    cursor: -webkit-image-set(url("../images/arrow_up@1x.png") 1x, url("../images/arrow_up@2x.png") 2x) 22 32, pointer;
    /* Webkit */ }
    .projectcontrols.controls-top a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: inherit; }
  .projectcontrols.controls-close {
    display: block;
    right: 20px;
    top: 20px;
    height: 40px;
    width: 40px;
    z-index: 15;
    background-size: 20px;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url("../images/close.png");
    cursor: pointer; }

.project--gallery {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 80px; }

.project--gallery--maxwidth {
  position: relative;
  width: 100%;
  max-width: 66vw;
  margin: 0 auto; }

.project--imageinfo-wrapper {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.25rem; }

.project--imageinfo--count {
  font-size: 0.85rem; }

.project--imageinfo--count-slash {
  padding-left: 0.15rem;
  padding-right: 0.15rem; }

.project--desc-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 0.85rem;
  line-height: 1.25em;
  letter-spacing: 0.02em;
  z-index: 15;
  position: relative; }

.project--desc {
  padding-top: 15px;
  padding-bottom: 30px;
  width: 15em;
  padding-right: 5em; }

.project--shortdesc {
  padding-top: 15px;
  padding-bottom: 30px;
  flex-grow: 1;
  max-width: 30em; }

.project--gallery-slide {
  position: absolute;
  left: -9999em;
  top: -9999em;
  width: 100%;
  height: 0;
  overflow: hidden; }
  .project--gallery-slide.first {
    top: 0;
    left: 0;
    position: relative; }
  .project--gallery-slide img, .project--gallery-slide video {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.contentwrapper-about {
  display: flex;
  flex-direction: row; }

.about-main {
  flex-grow: 1; }

.about-image {
  width: 100%;
  display: block; }

.about-sidebar {
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: 1.25rem;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px; }

.about-zoomimage {
  width: 33vw;
  height: 21.78vw;
  position: fixed;
  overflow: hidden;
  z-index: 20;
  bottom: 0;
  right: 0;
  background-color: #000;
  pointer-events: none;
  opacity: 0; }
  .about-zoomimage.js-visible {
    opacity: 1; }
  .about-zoomimage img {
    position: absolute;
    left: 0;
    top: 0;
    width: 3500px; }

@media (min-width: 1025px) {
  .about-sidebar {
    width: 33vw;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px; } }

.contentwrapper--mobile {
  width: 100%; }

.contentwrapper--mobile--project {
  width: 100%; }

.contentwrapper--mobile--gallery {
  position: relative;
  width: 100%;
  overflow: hidden; }

.contentwrapper--mobile--gallery-slide {
  width: 100vw;
  height: 0;
  display: none; }
  .contentwrapper--mobile--gallery-slide.first {
    display: block; }
  .contentwrapper--mobile--gallery-slide img, .contentwrapper--mobile--gallery-slide video {
    width: 100%; }

.contentwrapper--mobile--desc {
  display: none;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: 1rem;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px; }
  .contentwrapper--mobile--desc.js-visible {
    display: block; }

.contentwrapper--mobile--desc-title {
  font-size: 1.25rem;
  padding-bottom: 0.5em; }
